body {
  margin: 0;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiImageListItem-root {
  margin: 1vw;
}

.MuiImageListItem-img {
  object-fit: contain !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App-header {
  position: relative;
  top: 0;
}

#videoheaderDiv {
  position: relative;
  top: 0;
  z-index: 8;
}

.navAndHeaderDiv {
  position: relative;
  width: 100%;
  top: 0;
}

.containers {
  margin: 2vw 1vw 1vw 1vw;
  position: relative;
  width: 100%;
}

#headertext {
  float: right;
  position: absolute;
  text-align: right;
  font-size: 4vw;
  width: 100%;
  top: 0;
  margin-top: 10%;
  margin-right: 10%;
  text-transform: uppercase;
  line-height: 4vw;
  z-index: 9;
}

@media screen and (max-width: 900px) {
  #headertext {
    text-align: center;
    width: 100%;
    text-transform: capitalize;
    line-height: 2rem;
    margin-right: 0%;
  }

  #profilbildDiv {
    text-align: center !important;
  }

  #profilbild {
    width: 70% !important;
  }

  /*
  .MuiGrid-item {
    max-width: 100% !important;
  }
*/
  .MuiTypography-root {
    text-align: center;
  }

  .MuiTimeline-root {
    margin: 0vw !important;
  }
}

@media screen and (max-width: 450px) {
  #profilbildDiv {
    text-align: center !important;
  }

  #profilbild {
    width: 90% !important;
  }
}

#profilbildDiv {
  margin: 2vw;
}

#profilbild {
  width: 100%;
}
